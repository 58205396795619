// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Set, Router, Route, PrivateSet } from '@redwoodjs/router'

import AppNavBarLayout from 'src/layouts/AppNavBarLayout/AppNavBarLayout'
import ChatLayout from 'src/layouts/ChatLayout'
import FineTuningNavBarLayout from 'src/layouts/FineTuningNavBarLayout/FineTuningNavBarLayout'
import ScaffoldLayout from 'src/layouts/ScaffoldLayout'
import TrainingWebsitesNavBarLayout from 'src/layouts/TrainingWebsitesNavBarLayout/TrainingWebsitesNavBarLayout'

import { useAuth } from './auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={ScaffoldLayout} title="KvStorages" titleTo="adminKvStorages" buttonLabel="New KvStorage" buttonTo="adminNewKvStorage">
          <Route path="/admin/kv-storages/new" page={AdminKVStorageNewKVStoragePage} name="adminNewKvStorage" />
          <Route path="/admin/kv-storages/{id}/edit" page={AdminKVStorageEditKVStoragePage} name="adminEditKvStorage" />
          <Route path="/admin/kv-storages/{id}" page={AdminKVStorageKVStoragePage} name="adminKvStorage" />
          <Route path="/admin/kv-storages" page={AdminKVStorageKVStoragesPage} name="adminKvStorages" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={ScaffoldLayout} title="Templates" titleTo="adminTemplates" buttonLabel="New Template" buttonTo="adminNewTemplate">
          <Route path="/admin/templates/new" page={AdminTemplateNewTemplatePage} name="adminNewTemplate" />
          <Route path="/admin/templates/{id}/edit" page={AdminTemplateEditTemplatePage} name="adminEditTemplate" />
          <Route path="/admin/templates/{id}" page={AdminTemplateTemplatePage} name="adminTemplate" />
          <Route path="/admin/templates" page={AdminTemplateTemplatesPage} name="adminTemplates" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={ScaffoldLayout} title="ChatTemplates" titleTo="adminChatTemplates" buttonLabel="New Chat Template" buttonTo="adminNewChatTemplate">
          <Route path="/admin/chat/templates/new" page={AdminChatTemplateNewChatTemplatePage} name="adminNewChatTemplate" />
          <Route path="/admin/chat/templates/{id}/edit" page={AdminChatTemplateEditChatTemplatePage} name="adminEditChatTemplate" />
          <Route path="/admin/chat/templates/{id}" page={AdminChatTemplateChatTemplatePage} name="adminChatTemplate" />
          <Route path="/admin/chat/templates" page={AdminChatTemplateChatTemplatesPage} name="adminChatTemplates" />
        </Set>
        <Route path="/admin/chat/templates/{id}/preview" page={AdminChatTemplatePreviewChatTemplatePage} name="adminPreviewChatTemplate" />
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={FineTuningNavBarLayout}>
          <Route path="/fine-tuning/playground" page={FineTuningPlaygroundPage} name="fineTuningPlayground" />
          <Route path="/fine-tuning/examples" page={FineTuningExamplesPage} name="fineTuningExamples" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles={['admin', 'training']}>
        <Set wrap={TrainingWebsitesNavBarLayout}>
          <Route path="/training-websites/new" page={TrainingWebsitesNewTrainingWebsitePage} name="newTrainingWebsite" />
          <Route path="/training-websites/{id}" page={TrainingWebsitesEditPage} name="editTrainingWebsite" />
          <Route path="/training-websites" page={TrainingWebsitesPage} name="trainingWebsites" />
          <Route path="/template-editor" page={TemplateEditorPage} name="templateEditor" />
          <Route path="/template-editor/new" page={TemplateEditorNewPage} name="templateEditorNew" />
          <Route path="/template-editor/validator" page={TemplateEditorValidatorPage} name="templateEditorValidator" />
          <Route path="/template-editor/{id}" page={TemplateEditorEditPage} name="templateEditorEdit" />
          <Route path="/template-sections" page={TemplateSectionsPage} name="templateSections" />
          <Route path="/template-sections/{id}/edit" page={TemplateSectionEditPage} name="editTemplateSection" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login" roles="admin">
        <Set wrap={TrainingWebsitesNavBarLayout}>
          <Route path="/admin/recent-signups" page={RecentSignupsPage} name="recentSignups" />
        </Set>
      </PrivateSet>
      <Set>
        <Set wrap={ChatLayout}>
          <Route path="/website-chat/{id}" page={WebsiteChatPage} name="websiteChat" />
          <Route path="/website-chat/{id}/settings/{tab}" page={WebsiteChatSettingsPage} name="websiteChatSettingsTab" />
          <Route path="/website-chat/{id}/{page...}" page={WebsiteChatPage} name="websiteChatPage" />
        </Set>
      </Set>
      <PrivateSet unauthenticated="login">
        <Set wrap={ChatLayout}>
          <Route path="/domains" page={DomainDomainsPage} name="domains" />
          <Route path="/domains/add" page={DomainDomainAddPage} name="domainAdd" />
          <Route path="/domains/{id}/confirm-dns" page={DomainDomainConfirmDnsPage} name="domainConfirmDns" />
          <Route path="/domains/{id}/verify-nameservers" page={DomainDomainVerifyNameserversPage} name="domainVerifyNameservers" />
          <Route path="/domains/search" page={DomainDomainSearchPage} name="domainSearch" />
          <Route path="/domains/{id}" page={DomainDomainPage} name="domain" />
          <Route path="/purchase-success" page={PurchaseSuccessPage} name="purchaseSuccess" />
          <Route path="/upgrade-purchase-success" page={UpgradePurchaseSuccessPage} name="upgradePurchaseSuccess" />
        </Set>
      </PrivateSet>
      <PrivateSet unauthenticated="login">
        <Set>
          <Route path="/" page={HomePage} name="home" />
        </Set>
      </PrivateSet>
      <Set wrap={AppNavBarLayout}>
        <Route path="/website/{id}" redirect="/website-chat/{id}" name="website" />
        <Route path="/website/{id}/settings" redirect="/website-chat/{id}/settings/general" name="websiteSettings" />
        <Route path="/website/{id}/plans" redirect="/website-chat/{id}/settings/plans" name="websitePlansSettings" />
        <Route path="/website/{id}/custom-domain" redirect="/website-chat/{id}/settings/domain" name="websiteCustomDomainSettings" />
        <Route path="/website/{id}/delete-website" redirect="/website-chat/{id}/settings/delete" name="websiteDeleteWebsiteSettings" />
      </Set>
      <Route path="/template-builder/1" page={TemplateOneBuilderPage} name="templateOneBuilder" />
      <Route path="/template-builder/2" page={TemplateTwoBuilderPage} name="templateTwoBuilder" />
      <Route path="/template-builder/3" page={TemplateThreeBuilderPage} name="templateThreeBuilder" />
      <Route path="/template-builder/4" page={TemplateFourBuilderPage} name="templateFourBuilder" />
      <Route path="/template-builder/5" page={TemplateFiveBuilderPage} name="templateFiveBuilder" />
      <Route path="/template-builder/6" page={TemplateSixBuilderPage} name="templateSixBuilder" />
      <Route path="/template-builder/7" page={TemplateSevenBuilderPage} name="templateSevenBuilder" />
      <Route path="/template-builder/8" page={TemplateEightBuilderPage} name="templateEightBuilder" />
      <Route path="/template-builder/9" page={TemplateNineBuilderPage} name="templateNineBuilder" />
      <Route path="/template-builder/10" page={TemplateTenBuilderPage} name="templateTenBuilder" />
      <Route path="/website-preview" page={WebsitePreviewPage} name="websitePreview" />
      <Route path="/login-success" page={LoginSuccessPage} name="loginSuccess" />
      <Route path="/logout" page={LogoutPage} name="logout" />
      <Route path="/signup" page={SignupPage} name="signup" />
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/claim" page={ClaimPage} name="claim" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
